import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vue-frag';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import store from '@/store';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.component('default-layout', DefaultLayout);

Vue.filter('translateStatus', (value: string) => {
  if (value === 'APPROVED') return 'ดำเนินการแล้ว';
  if (value === 'PENDING') return 'รอการดำเนินการ';
  if (value === 'REJECTED') return 'ปฏิเสธการดำเนินการ';
  return value;
});

store.dispatch('Auth/autoLogin').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
