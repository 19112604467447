/* eslint-disable */
import HttpRequest from '@/services/HttpRequest';

export abstract class AppService {
  private http: HttpRequest;

  private endpoint: string;

  protected sessionStorage: Storage;

  constructor() {
    this.http = new HttpRequest();
    this.endpoint = process.env.VUE_APP_API_ENDPOINT;
    this.sessionStorage = window.sessionStorage;
  }

  protected async makeRequest(method: string, path: string, payload?: object): Promise<any> {
    let response;
    if (method === 'GET') {
      response = this.http.get(`${this.endpoint}${path}`);
    }
    if (method === 'POST') {
      response = await this.http.post(`${this.endpoint}${path}`, payload);
    }
    if (method === 'PATCH') {
      response = await this.http.patch(`${this.endpoint}${path}`, payload);
    }
    return response;
  }
}

export default { AppService };
