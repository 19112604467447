/* eslint-disable */
import UserService from '@/services/UserService';
import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';

const userService = new UserService();

@Module({ namespaced: true })
export default class Profile extends VuexModule {
  public balance: number = 0;

  public bank: string = '';

  public accountNumber: string = '';

  public accountName: string = '';

  @Mutation
  public setbank(payload: {
    bank: string;
    account_number: string;
    account_name: string;
  }) {
    console.log('bank xxx', payload);
    this.bank = payload.bank;
    this.accountNumber = payload.account_number;
    this.accountName = payload.account_name;
  }

  @Mutation
  public setBalance(payload: number) {
    this.balance = payload;
  }

  @Action
  public async updateBank() {
    const { data } = await userService.getProfile();
    this.context.commit('setbank', data);
  }

  @Action
  public async fetchBalance() {
    const { data } = await userService.checkBalance();
    this.context.commit('setBalance', data.result.netbanlance);
  }
}
