














import { Component, Vue } from 'vue-property-decorator';
import BaseTopNavigatorBar from '@/components/BaseTopNavigatorBar.vue';
import BaseBottomNavigatorBar from '@/components/BaseBottomNavigatorBar.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import BaseSnackbar from '@/components/BaseSnackbar.vue';

@Component({
  components: {
    BaseTopNavigatorBar,
    BaseBottomNavigatorBar,
    BaseFooter,
    BaseSnackbar,
  },
})
export default class DefaultLayout extends Vue {}
