








































import { Component, Vue } from 'vue-property-decorator';
import Auth from '@/connector/Auth.vue';
import ProfileConnector from '@/connector/Profile.vue';

@Component({
  components: {
    Auth,
    ProfileConnector,
  },
})
export default class BaseBottomNavigatorBar extends Vue {
  playGame(urlObject: { desktopGameUrl: string; mobileGameUrl: string }): void {
    if (this.$vuetify.breakpoint.mobile) {
      window.open(urlObject.mobileGameUrl);
    } else {
      window.open(urlObject.desktopGameUrl);
    }
  }

  notifyPlayerToTopUp() {
    this.$store.commit('Snackbar/showNotification', { message: 'กรุณาเติมเงินเพื่อเล่นเกม', error: true });
  }
}
