import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AcceptableAlphabetMixin extends Vue {
  // eslint-disable-next-line class-methods-use-this
  public isEnglishAlphabet(event: KeyboardEvent) {
    const char = String.fromCharCode(event.keyCode); // Get the character
    if (/^[A-Za-z0-9]+$/.test(char)) return true;
    event.preventDefault();
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  // public isNumericAlphabet(event: KeyboardEvent) {
  //   const char = String.fromCharCode(event.keyCode); // Get the character
  //   if (/^[0-9]+$/.test(char)) return true;
  //   event.preventDefault();
  //   return false;
  // }
}
