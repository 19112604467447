/* eslint-disable */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/Register.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('@/pages/Deposit.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('@/pages/Withdraw.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/commission',
    name: 'Commission',
    component: () => import('@/pages/Commission.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('@/pages/ContactUs.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  console.log('to.meta', to.meta);
  if (to.meta.requiredLogin && store.state.Auth.isLoggedIn) {
    next();
  } else if (to.meta.requiredLogin && !store.state.Auth.isLoggedIn) {
    next('login');
  } else {
    next();
  }
});

export default router;
