




























import { Prop, Component, Mixins } from 'vue-property-decorator';
import AcceptableAlphabetMixin from '@/mixins/AcceptableAlphabetMixin';

@Component
export default class LoginForm extends Mixins(AcceptableAlphabetMixin) {
  @Prop({ type: Number, default: 12 }) readonly columnSize: number|undefined;

  public username: string = '';

  public password: string = '';

  public getFormData(): { username: string; password: string } {
    return {
      username: this.username,
      password: this.password,
    };
  }
}
