




















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Auth from '@/connector/Auth.vue';
import LoginForm from '@/components/LoginForm.vue';

@Component({
  components: {
    Auth,
    LoginForm,
  },
})
export default class BaseTopNavigatorBar extends Vue {
  get loginData() {
    // eslint-disable-next-line max-len
    const data = (this.$refs.LOGIN_FORM as Vue & { getFormData: () => { username: string; password: string; type: string } }).getFormData();
    return data;
  }
}
