








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Profile = namespace('Profile');

@Component
export default class ProfileConnector extends Vue {
  @Profile.State
  public balance!: boolean;

  @Profile.State
  public bank!: string;

  @Profile.State
  public accountNumber!: string;

  @Profile.State
  public accountName!: string;

  @Profile.Action
  public fetchBalance!: () => {}

  @Profile.Action
  public updateBank!: () => {}

  public async checkBalance() {
    await this.fetchBalance();
    await this.updateBank();
  }

  async created() {
    await this.fetchBalance();
    await this.updateBank();
  }
}
