var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"base-header"},[_c('div',{staticClass:"header__content"},[_c('div',{staticClass:"header__content--wrapper"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('center',[_c('img',{staticClass:"header__logo",attrs:{"src":require('@/assets/logo.png'),"width":"100%","height":"100%","alt":""}})])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var logout = ref.logout;
return [_c('v-row',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 100%;' : 'height: 70%;'),attrs:{"no-gutters":"","justify":"end","align-content":"center"}},[_c('v-btn',{attrs:{"depressed":"","rounded":"","color":"red","dark":""},on:{"click":logout}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" ออกจากระบบ ")])])],1)]}},{key:"loggedOut",fn:function(ref){
var login = ref.login;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('login-form',{ref:"LOGIN_FORM",attrs:{"column-size":12,"field-background-color":"black","dark":true}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"mb-3",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('center',[_c('v-btn',{staticClass:"mr-1",attrs:{"large":"","rounded":"","depressed":"","dark":"","color":"blue","width":"150"},on:{"click":function($event){return login({
                        username: _vm.loginData.username,
                        password: _vm.loginData.password,
                        type: _vm.$vuetify.breakpoint.mobile ? 'm' : 'd',
                      })}}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" เข้าสู่ระบบ ")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('center',[_c('v-btn',{attrs:{"to":{ name: 'Register' },"large":"","rounded":"","depressed":"","dark":"","width":"150","color":"green"}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" สมัครสมาชิก ")])])],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":"","justify":"center","align-content":"center"}},[_c('login-form',{ref:"LOGIN_FORM",attrs:{"column-size":4,"field-background-color":"black","dark":true}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","no-gutters":"","align":"center"}},[_c('v-btn',{attrs:{"depressed":"","rounded":"","color":"amber","dark":""},on:{"click":function($event){return login({
                        username: _vm.loginData.username,
                        password: _vm.loginData.password,
                        type: _vm.$vuetify.breakpoint.mobile ? 'm' : 'd',
                      })}}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" เข้าสู่ระบบ ")])]),_c('v-btn',{staticClass:"ml-1",attrs:{"to":{ name: 'Register' },"rounded":"","depressed":"","dark":"","color":"green"}},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" สมัครสมาชิก ")])])],1)],1)],1):_vm._e()]}}])})],1)],1)],1)]),_c('div',{staticStyle:{"margin-top":"0px"},attrs:{"no-gutters":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%","background-color":"black"}},[_c('div',{staticStyle:{"max-width":"1200px","width":"100%"}},[_c('v-tabs',{staticStyle:{"width":"100%"},attrs:{"grow":"","dark":"","background-color":"black"}},[_c('v-tabs-slider',{attrs:{"color":"transparent"}}),_c('v-tab',{attrs:{"exact":"","active-class":"pink--text font-weight-black","to":{ name: 'Home' }}},[_vm._v(" หน้าแรก ")]),_c('v-tab',{attrs:{"exact":"","active-class":"pink--text font-weight-black","to":{ name: 'Dashboard' }}},[_vm._v(" บัญชีเกมส์ ")]),_c('v-tab',{attrs:{"exact":"","active-class":"pink--text font-weight-black","to":{ name: 'Deposit' }}},[_vm._v(" ฝากเงิน ")]),_c('v-tab',{attrs:{"exact":"","active-class":"pink--text font-weight-black","to":{ name: 'Withdraw' }}},[_vm._v(" ถอนเงิน ")]),_c('v-tab',{attrs:{"exact":"","active-class":"pink--text font-weight-black","to":{ name: 'Commission' }}},[_vm._v(" แนะนำเพื่อน ")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }